import { DotLottie } from '@lottiefiles/dotlottie-web';

export function Lottie(props) {
    let lottie
    const src = props.src
    return {
        $template: '#lottie-template',
        createLottie(props) {
            lottie = new DotLottie({
                autoplay: true,
                loop: true,
                canvas: props.canvas,
                src: src
            });
            setTimeout(function() {
                lottie.play()
            }, 500);
        }
    }
}