import '@fortawesome/fontawesome-free/css/all.css';
import 'lazysizes';

import '../css/app.scss';

//import petite-vue and create app
import { createApp } from 'petite-vue'

import { Lottie } from './_lottie'
import { basicSwipers, swipers } from './_swiper'
import { Timer } from './_timer'

const main = async () => {
  // Create our vue instance
  const app = createApp({
    // share it with app scopes
    Lottie,
    basicSwipers,
    swipers,
    Timer,
  }).mount()

  // Mount the app
  return app.mount('#page');
};

// Execute async function
main().then(() => {
  // AOS.init({
  //   once: true
  // })
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR!!")
  });
}