import Swiper from 'swiper';
import { Pagination, Navigation, FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

const allBasicSwipers = document.querySelectorAll(".basic-swiper");
export const basicSwipers = allBasicSwipers.forEach(function (thisSwiper, i) {
	const elPagination = thisSwiper.querySelector(".swiper-pagination");
	const swiper = new Swiper(thisSwiper, {
		slidesPerView: 1,
		// speed: 3000,
		autoHeight: false,
		loop: true,
		grabCursor: true,
		autoplay: {
			delay: 2500
		},
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
		pagination: {
			el: elPagination,
			dynamicBullets: true,
			clickable: true,
		},
		modules: [Pagination],
	});
});

const allGeneralSwipers = document.querySelectorAll(".general-swiper");

export const swipers = allGeneralSwipers.forEach(function (thisSwiper, i) {

	const elPagination = thisSwiper.querySelector(".swiper-pagination");
	const elPrev = thisSwiper.querySelector(".swiper-button-prev");
	const elNext = thisSwiper.querySelector(".swiper-button-next");

	let colMb = 1;
	let colSm = 1;
	let colMd = 1;
	let colLg = 1;
	if(thisSwiper.hasAttribute('data-col-mb')) {	
		const colMbData = thisSwiper.getAttribute('data-col-mb');
		const colSmData = thisSwiper.getAttribute('data-col-sm');
		const colMdData = thisSwiper.getAttribute('data-col-md');
		const colLgData = thisSwiper.getAttribute('data-col-lg');

		colMb = (colMbData.length && colMbData != '') ? colMbData : 1;
		colSm = colSmData != '' ? colSmData : colMb;
		colMd = colMdData != '' ? colMdData : colSm;
		colLg = colLgData != '' ? colLgData : colMd;

		console.log(colMb, colSm, colMd, colLg);
	} 
	const spaceBetween = thisSwiper.getAttribute('data-gap') ? Number(thisSwiper.getAttribute('data-gap')) : 32;
	const loop = thisSwiper.getAttribute('data-loop') ? Boolean(thisSwiper.getAttribute('data-loop')) : false;
	const center = thisSwiper.getAttribute('data-center') ? Boolean(thisSwiper.getAttribute('data-center')) : false;
	const swiper = new Swiper(thisSwiper, {
		slidesPerView: colMb != 'auto' ? +colMb : 'auto',
        slidesPerGroup: 1,
		breakpoints: {
			640: {
				slidesPerView: colSm != 'auto' ? +colSm : 'auto',
			},
			768: {
				slidesPerView: colMd != 'auto' ? +colMd : 'auto',
			},
			1024: {
				slidesPerView: colLg != 'auto' ? +colLg : 'auto',
            }
		},
		// speed: 2000,
		autoHeight: false,
        loop: loop,
		centeredSlides: false,
        grabCursor: true,
		spaceBetween: spaceBetween,
		autoplay: {
			delay: 2500
		},
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 2,
		},
		freeMode: {
			enabled: true,
			sticky: true
		},
		pagination: {
			el: elPagination,
			dynamicBullets: true,
			clickable: true,
		},
		navigation: {
			nextEl: elNext,
			prevEl: elPrev,
		},
	
        modules: [Pagination, Navigation, FreeMode],
	});
});