export function Timer(props) {
    console.log('props', props)
    let intervalID;
    const targetDateTime = new Date(props.dateTime).getTime();

    function formatNumber(number) {
        return number.toString().padStart(2, '0');
    }

    return {
        $template: '#timer-template',
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        expired: false,
        expiryText: props.expiryText,
        startTimer() {
            this.updateTime();
            intervalID = setInterval(() => {
                this.updateTime();
            }, 1000);
        },
        updateTime() {
            const now = new Date().getTime();
            const timeRemaining = targetDateTime - now;

            if (timeRemaining > 0) {
                this.days = formatNumber(Math.floor(timeRemaining / (1000 * 60 * 60 * 24)));
                this.hours = formatNumber(Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                this.minutes = formatNumber(Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)));
                this.seconds = formatNumber(Math.floor((timeRemaining % (1000 * 60)) / 1000));
            } else {
                clearInterval(intervalID);
                this.days = this.hours = this.minutes = this.seconds = '00';
                this.expired = true;
            }
        }
    }
}